<script setup>
import { storeToRefs } from 'pinia';
import { useState } from '#app';
import { useWebview } from '~/stores/webview';
import { useMessageStore } from '~/stores/messages';
import { useSideBar } from '~/stores/sideBar';

// Global
const sideBarStore = useSideBar();
const { toggleSideBar, toggleSideBarSubNav } = sideBarStore;

// Fetch messages
const messagesStore = useMessageStore();
const { updateMessages } = messagesStore;

const staticKeys = await useFetchMessages();

updateMessages(staticKeys.value);

useState('state_errorMsgObj', () => {
    return {
        PATCH: staticKeys.value?.account_form_update_error,
        GET: staticKeys.value?.account_form_error_load,
        DELETE: staticKeys.value?.account_form_delete_error
    }
});

// Webview
const webViewStore = useWebview();
const { webview } = storeToRefs(webViewStore);
const isVisible = computed(() => !webview.value);

// Show feedback form if user has deleted account
// Show support text if user can not log in
if (process.client) {
    const feedback = localStorage.getItem('showFeedbackForm');
    const support = localStorage.getItem('showSupportNote');

    if (feedback || support) {
        setTimeout(() => {
            // Close sidebar subnav
            toggleSideBarSubNav({
                open: false
            }, false)

            // Open feedback form in sidebar
            toggleSideBar({
                open: true,
                content: feedback ? 'feedbackForm' : 'supportNote'
            })
        }, 1000);
    }
}
</script>

<template>
    <div class="page-wrapper">
        <PageDefaultHeader v-if="isVisible" />

        <LazySideBar v-if="isVisible" />
        <LazyUserAccountOnBoarding v-if="isVisible" />

        <slot />
        <PageDefaultFooter v-if="isVisible" />
    </div>
</template>

<style lang="scss">
.page-wrapper {
    @include page-grid;

    grid-template-rows: auto 1fr auto;
    min-height: 100dvh;
    overflow: hidden;
}
</style>
